import React from 'react';
import { Link } from 'react-router-dom';
import './Aszf.scss';

export default function Aszf() {
  return (
    <div className='ASZF'>
      <ol className='behuzas'>
        <li>
          A jelentkezési lap egyben <strong>tanulói szerződés</strong> a
          Tanuló/Szülő és a (László Ernő e.v, : 9400, Székhely: Sopron, Cserfa
          utca 1. Adószám: 57322258-1-28) Szolgáltató között.
        </li>
        <li>
          A Jelentkezési lap kitöltését követően a <strong>lemondásra</strong> a
          Szülő/Tanuló jogosult, amelyet <strong>írásban</strong> tehet
          bármelyik elérhetőségünkön.
        </li>
        <li>
          <strong>
            A jelentkezési lap leadása nem jelenti az automatikus tanóra
            hozzárendelést
          </strong>
          , a Szolgáltató kapacitás és elérhető erőforrások alapján osztja ki az
          időpontot.
        </li>
        <li>
          Szolgáltató fenntartja magának a jogot, hogy előzetes tájékoztatás
          alapján a szolgáltatást részben vagy egészben megváltoztassa, a
          jelentkezést indokolt esetben visszamondja, amelyről a szolgáltatás
          igénybevételét megelőzően, ésszerű határidőben tájékoztatja a Szülőt,
          Tanulót. Ebben az esetben – a már megfizetett részvételi díjon felül –
          egyéb követelést Szülő, Tanuló nem támaszthat. A módosítással
          kapcsolatban, amennyiben nem érkezik ellenvetés legkésőbb a módosítást
          követő első foglalkozásig a Szolgáltató elfogadottnak tekinti és az a
          megállapodás részévé válik.
        </li>
        <li>
          A tanórák során a tanulók számára az előzetes ismeretek alapján a
          személyiségnek legjobban megfelelő tanító kerül kiválasztásra,
          amennyiben a Tanuló/Szülő változtatást szeretne és ezt a Szolgáltató
          felé jelzi a Szolgáltató igyekszik a tőle telhető módon a kérést
          kapacitás függvényében minél hamarabb teljesíteni.
        </li>
        <li>
          <strong>Fizetésekkel</strong> kapcsolatban a Szolgáltató lehetőséget
          biztosít
          <strong> készpénzes</strong>, <strong>bankkártyás</strong>, illetve{' '}
          <strong>átutalásos</strong> fizetési módra is. Ezeket a Számlázz.hu
          rendszeréből kiállított számla alapján kérjük határidőre megfizetni.
        </li>
        <li>
          A hiányzások a tanfolyam adminisztrációs felületén (
          <span className='darkBlue'>https://belepes.scriptantia.hu</span>),
          emailben esetleg telefonon jelezhetően.{' '}
          <strong className='red'>Óra lemondási feltételek:</strong>
          <ol className='child' type='a'>
            <li>
              Egy félév során <strong>2 óra ingyenesen lemondható</strong> 24
              órával a tanóra előtt. (Betegség és igazolt hiányzások
              kivételével)
            </li>
            <li>
              Minden egyéb esetben a tanóra megkezdése előtt lemondott órák
              <strong>díjának 20%-a kiszámlázásra</strong> kerül.
            </li>
            <li>
              A lefoglalt tanórán{' '}
              <strong>értesítés nélküli távolmaradás</strong> esetében a
              foglalkozás <strong>díjának 100%-a </strong>kerül felszámolásra.
            </li>
            <li>
              Óra pótlására kapacitás függvényében van lehetőség. Az óra
              lemondás nem jogosít fel automatikusan pótlásra.
            </li>

            <li>
              <strong>
                Két értesítés nélküli távolmaradás után az időpont elveszik{' '}
              </strong>
              és fenntartjuk a szolgáltatás felmondásának jogát. Az esetleges
              fennálló egyenleget visszafizetjük.
            </li>
            <li>
              Természetesen igyekszünk minden esetet a lehető legkorrektebben
              kezelni így fenntartjuk a jogot a fentiek alkalmazásának
              mellőzésére, amennyiben a kialakult helyzetet úgy ítéljük meg.
            </li>
          </ol>
          <li>
            A foglalkozások díjazása egységesen a{' '}
            <Link target='_blank' to={'https://www.scriptantia.hu/araink'}>
              www.scriptantia.hu/araink
            </Link>{' '}
            oldalon érhető el.
          </li>
          <li>
            A <strong>Tanuló/Szülő jogosult</strong> a Szerződést azonnali
            hatállyal <strong>felmondani</strong>, amennyiben Szolgáltató nem
            teljesíti a vállalt szolgáltatást.
          </li>
          <li>
            <strong>Szolgáltató jogosult</strong> a Szerződést azonnali
            hatállyal <strong>felmondani</strong>
            és a szolgáltatások nyújtását megtagadni, amennyiben: • Résztvevő a
            házirendet súlyosan megszegi; • Résztvevő nem rendeltetésszerűen
            használja a rendelkezésére bocsátott eszközöket; • Tanuló a
            társaival, Szolgáltatóval vagy annak alkalmazottjaival durván
            viselkedik, alkohol vagy drogok befolyása alatt áll, illetve
            fenyegető, sértő vagy más elfogadhatatlan viselkedést tanúsít; •
            Tanuló/Szülő nem teljesíti a fizetési kötelezettségét.
          </li>
          <li>
            A Szolgáltató a Tanuló/Szőlővel való kapcsolattartás céljából egy
            adminisztrációs oldalt tart fent (
            <span className='darkBlue'>https://belepes.scriptantia.hu</span>),
            ahol a Tanuló/Szülő a megtartott órákat ellenőrizheti, hiányzásokat
            bejelentheti az órarendbe betekinthet. Az oldalon található
            információk képzik a számlázás alapját, a megtartott órát követő 30
            napon túl reklamációt nem tudunk elfogadni. A tanórák{' '}
            <strong>módosítása, áthelyezése, lemondása</strong> alapvetően ezen
            az oldalon keresztül történik.
          </li>
        </li>
        <strong></strong>
      </ol>
    </div>
  );
}
